import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const plans = ['Starter', 'Basic', 'Premium', 'Enterprise'];
const priceIdMapping:{[key:string]: {[key:string]:string}} = {
	'Starter': {
		'month': 'price_1Q9i4CRwi8cbrWgq2SkImsFF',
		'year': 'price_1Q9i4DRwi8cbrWgqhsDqMUIk'
	},
	'Basic': {
		'month': 'price_1Q9i4FRwi8cbrWgq1HzkR61o',
		'year': 'price_1Q9i4GRwi8cbrWgq4P2ruFB1'
	},
	'Premium': {
		'month': 'price_1Q9i4HRwi8cbrWgq1dY3KNvA',
		'year': 'price_1Q9i4IRwi8cbrWgq3dMlCoXx'
	},
	'Enterprise': {
		'month': 'price_1Q9i4KRwi8cbrWgqg9xdtGQZ',
		'year': 'price_1Q9i4LRwi8cbrWgqgUXZO6yE'
	}
};
const getPriceId = (plan:string, interval:string) => {
	return priceIdMapping[plan]?.[interval] || "price_1Q9i4CRwi8cbrWgq2SkImsFF";
};

const SignupForm: React.FC = () => {
	const location = useLocation();
	const [formData, setFormData] = useState({
		email: '',
		username: '',
		firstName: '',
		lastName: '',
		password: '',
		confirmPassword: '',
		plan: 'Free'
	});
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [message, setMessage] = useState<string | null>(null);
	const [messageSuccess, setMessageSuccess] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		document.getElementById("header")!.classList.add("bg-white");
		return () => {
			document.getElementById("header")!.classList.remove("bg-white");
		}
	}, []);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const plan = params.get('plan');
		if (plan && plans.includes(plan)) {
			setFormData((prevData) => ({ ...prevData, plan }));
		}
	}, [location.search]);

	const validateForm = () => {
		const newErrors: { [key: string]: string } = {};
		if (!formData.email) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+.\S+/.test(formData.email)) {
			newErrors.email = 'Email address is invalid';
		}
		if (!formData.password) {
			newErrors.password = 'Password is required';
		} else if (formData.password.length < 6) {
			newErrors.password = 'Password must be at least 6 characters';
		}
		if (formData.password !== formData.confirmPassword) {
			newErrors.confirmPassword = 'Passwords do not match';
		}
		return newErrors;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handlePlanChange = (plan: string) => {
		setFormData({
			...formData,
			plan
		});
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const validationErrors = validateForm();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}
		setIsSubmitting(true);
		const params = new URLSearchParams(location.search);
		const interval = params.get('interval') || "month";
		try {
			const response = await fetch('https://api.tracknlist.com/api/signup', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ ...formData, plan: getPriceId(formData.plan, interval) })
			});

			if (response.ok) {
				setMessageSuccess(true);
				setMessage('Verification email sent. Please check your inbox.');
			} else {
				const errorData = await response.json();
				setMessage(errorData.error || 'An error occurred. Please try again.');
			}
		} catch (error) {
			setMessage('An error occurred. Please try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<div className="flex min-h-full flex-1 dark:bg-gray-900 mt-[40px]">
				<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div>
							<h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
								Create your account
							</h2>
							<p className="mt-2 text-sm leading-6 text-gray-500 dark:text-gray-400">
								Already have an account?{' '}
								<Link to="/login" className="font-semibold text-indigo-600 hover:text-indigo-500">
									Sign in
								</Link>
							</p>
						</div>

						<div className="mt-10">
							<form onSubmit={handleSubmit} className="space-y-6">
								<div>
									<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										Email address
									</label>
									<div className="mt-2">
										<input
											id="email"
											name="email"
											type="email"
											value={formData.email}
											onChange={handleChange}
											required
											autoComplete="email"
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white"
										/>
										{errors.email && (
											<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.email}</p>
										)}
									</div>
								</div>

								<div>
									<label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										Username
									</label>
									<div className="mt-2">
										<input
											id="username"
											name="username"
											type="text"
											value={formData.username}
											onChange={handleChange}
											required
											autoComplete="username"
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white"
										/>
									</div>
								</div>

								<div>
									<label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										First Name
									</label>
									<div className="mt-2">
										<input
											id="firstName"
											name="firstName"
											type="text"
											value={formData.firstName}
											onChange={handleChange}
											required
											autoComplete="given-name"
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white"
										/>
									</div>
								</div>

								<div>
									<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										Last Name
									</label>
									<div className="mt-2">
										<input
											id="lastName"
											name="lastName"
											type="text"
											value={formData.lastName}
											onChange={handleChange}
											required
											autoComplete="family-name"
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white"
										/>
									</div>
								</div>

								<div>
									<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										Password
									</label>
									<div className="mt-2">
										<input
											id="password"
											name="password"
											type="password"
											value={formData.password}
											onChange={handleChange}
											required
											autoComplete="new-password"
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white"
										/>
										{errors.password && (
											<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.password}</p>
										)}
									</div>
								</div>

								<div>
									<label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										Confirm Password
									</label>
									<div className="mt-2">
										<input
											id="confirmPassword"
											name="confirmPassword"
											type="password"
											value={formData.confirmPassword}
											onChange={handleChange}
											required
											autoComplete="new-password"
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-white"
										/>
										{errors.confirmPassword && (
											<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.confirmPassword}</p>
										)}
									</div>
								</div>

								<div>
									<label htmlFor="plan" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
										Select Plan
									</label>
									<div className="mt-2">
										<Menu as="div" className="relative inline-block text-left">
											<div>
												<MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-gray-800 dark:text-white dark:ring-gray-700">
													{formData.plan}
													<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
												</MenuButton>
											</div>

											<MenuItems
												transition
												className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in dark:bg-gray-800 dark:ring-gray-700"
											>
												<div className="py-1">
													{plans.map((plan) => (
														<MenuItem key={plan}>
															{({ active }) => (
																<button
																	type="button"
																	onClick={() => handlePlanChange(plan)}
																	className={`block w-full px-4 py-2 text-left text-sm ${active ? 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white' : 'text-gray-700 dark:text-white'}`}
																>
																	{plan}
																</button>
															)}
														</MenuItem>
													))}
												</div>
											</MenuItems>
										</Menu>
									</div>
								</div>

								<div>
									<button
										type="submit"
										disabled={isSubmitting}
										className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									>
										{isSubmitting ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
											</svg>
										) : (
											'Sign up'
										)}
									</button>
								</div>
							</form>
							{message && (
								<div className={`my-2 text-sm ${messageSuccess ? "text-green-500 dark:text-green-400" : "text-red-600 dark:text-red-400"}`}>
									{message}
								</div>
							)}
							<div className="mt-10">
								<div className="relative">
									<div aria-hidden="true" className="absolute inset-0 flex items-center">
										<div className="w-full border-t border-gray-200 dark:border-gray-700" />
									</div>
									<div className="relative flex justify-center text-sm font-medium leading-6">
										<span className="bg-white px-6 text-gray-900 dark:bg-gray-800 dark:text-white">Or continue with</span>
									</div>
								</div>

								<div className="mt-6 grid grid-cols-1 gap-4">
									<a
										href="https://api.tracknlist.com/api/google/login"
										className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent dark:bg-gray-800 dark:text-white dark:ring-gray-700"
									>
										<svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
											<path
												d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
												fill="#EA4335"
											/>
											<path
												d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
												fill="#4285F4"
											/>
											<path
												d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
												fill="#FBBC05"
											/>
											<path
												d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
												fill="#34A853"
											/>
										</svg>
										<span className="text-sm font-semibold leading-6">Google</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="relative hidden w-0 flex-1 lg:block">
					<img
						alt=""
						src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
						className="absolute inset-0 h-full w-full object-cover"
					/>
				</div>
			</div>
		</>
	);
};

export default SignupForm;
