import {Fragment, useState} from 'react';
import { Field, Label, Switch } from '@headlessui/react'
import {CheckIcon, MinusIcon} from '@heroicons/react/20/solid';
import {Link} from "react-router-dom";

const tiers = [
	{
		name: 'Starter',
		id: 'tier-starter',
		href: '/signup?plan=Starter',
		priceMonthly: 'Free',
		description: 'Limited version with basic features.',
		mostPopular: false,
		features: {
			crossListing: 'Up to 2 platforms',
			supportedPlatforms: '2 platforms',
			emailInventoryTracking: 'Limited',
			inAppMessages: 'Not available',
			inventoryManagementTools: true,
			aiInsights: 'Add-on',
			exportFormats: 'Basic (.txt, .csv)',
			scheduledAutoListingSupport: false,
			teamSupport: 'Add-on',
			additionalPlatforms: 'Add-on',
			prioritySupport: 'Add-on',
			customFeatures: false,
			totalInventoryItems: "unlimited",
			saleDetection: '100 items/month',
			hiResImageUpload: "5 images/listing",
			marketPlaceInventoryImport: "Add-on",
			bulkListing: '10 items',
			totalListingsPerMonth: '100 items',
			customTitles: false,
			draftListing: true,
			backgroundRemoval: '100 items',
			apiAccess: 'Add-on',
			accountingSoftwareIntegration: 'Add-on'
		}
	},
	{
		name: 'Basic',
		id: 'tier-basic',
		href: '/signup?plan=Basic',
		priceMonthly: 29.99, // Replace with your desired price
		description: 'Core features with expanded capabilities.',
		mostPopular: false,
		features: {
			crossListing: 'Up to 5 platforms',
			supportedPlatforms: '5 platforms',
			emailInventoryTracking: 'Inventory updates only',
			inAppMessages: 'Messages from platforms',
			inventoryManagementTools: true,
			aiInsights: 'Add-on',
			exportFormats: 'Basic (.txt, .csv, .xlsx)',
			scheduledAutoListingSupport: true,
			teamSupport: 'Add-on',
			additionalPlatforms: 'Add-on',
			prioritySupport: 'Add-on',
			customFeatures: false,
			totalInventoryItems: "Unlimited",
			saleDetection: '1000 items/month',
			bulkListing: '250 items',
			totalListingsPerMonth: '1000 items',
			customTitles: true,
			draftListing: true,
			hiResImageUpload: "15 images/listing",
			marketPlaceInventoryImport: "Add-on",
			backgroundRemoval: '1000 items/month',
			apiAccess: 'Add-on',
			accountingSoftwareIntegration: 'Add-on'
		}
	},
	{
		name: 'Premium',
		id: 'tier-premium',
		href: '/signup?plan=Premium',
		priceMonthly: 69.99, // Replace with your desired price
		description: 'Full access to advanced features and integrations.',
		mostPopular: true,
		features: {
			crossListing: 'Unlimited platforms',
			supportedPlatforms: 'Unlimited platforms',
			emailInventoryTracking: 'Full access',
			inAppMessages: 'Messages, notifications & alerts',
			inventoryManagementTools: true,
			aiInsights: true,
			exportFormats: 'All formats',
			scheduledAutoListingSupport: true,
			teamSupport: 'Add-on',
			additionalPlatforms: 'Included',
			prioritySupport: 'Add-on',
			customFeatures: false,
			totalInventoryItems: 'Unlimited',
			saleDetection: 'All listed items',
			bulkListing: 'Unlimited items',
			totalListingsPerMonth: '5000 items',
			customTitles: true,
			draftListing: true,
			backgroundRemoval: 'Unlimited',
			hiResImageUpload: "25 images/listing",
			marketPlaceInventoryImport: "Included",
			apiAccess: 'Included',
			accountingSoftwareIntegration: 'Included'
		}
	},
	{
		name: 'Enterprise',
		id: 'tier-enterprise',
		href: '/signup?plan=Enterprise',
		priceMonthly: 'Contact Us',
		description: 'Customized solutions with unlimited features.',
		mostPopular: false,
		features: {
			crossListing: 'Unlimited platforms',
			supportedPlatforms: 'Unlimited platforms',
			emailInventoryTracking: 'Full access',
			inAppMessages: 'Messages and responses',
			inventoryManagementTools: true,
			aiInsights: true,
			exportFormats: 'All formats',
			scheduledAutoListingSupport: true,
			teamSupport: 'Included',
			additionalPlatforms: 'Included',
			prioritySupport: 'Included',
			customFeatures: true,
			totalInventoryItems: 'Unlimited',
			saleDetection: 'All listed items',
			bulkListing: 'Unlimited',
			totalListingsPerMonth: 'Unlimited',
			customTitles: true,
			draftListing: true,
			hiResImageUpload: "25 images/listing",
			marketPlaceInventoryImport: "Included",
			backgroundRemoval: 'Unlimited',
			apiAccess: 'Included',
			accountingSoftwareIntegration: 'Included'
		}
	}
];

const sections = [
	{
		name: 'Features',
		features: [
			{
				name: 'Cross Listing',
				tiers: {
					Starter: 'Up to 2 platforms',
					Basic: 'Up to 5 platforms',
					Premium: 'Unlimited platforms',
					Enterprise: 'Unlimited platforms'
				}
			},
			{
				name: 'Supported Platforms',
				tiers: {
					Starter: '2 platforms',
					Basic: '5 platforms',
					Premium: 'Unlimited platforms',
					Enterprise: 'Unlimited platforms'
				}
			},
			{
				name: 'Email/Inventory Tracking',
				tiers: {Starter: false, Basic: 'Inventory updates only', Premium: 'Full access', Enterprise: 'Full access'}
			},
			{
				name: 'In-App Messages',
				tiers: {
					Starter: 'Not available',
					Basic: 'Messages from platforms',
					Premium: 'Messages, Notifications & Alerts',
					Enterprise: 'Messages, Notifications & Alerts'
				}
			},
			{name: 'Inventory Management Tools', tiers: {Starter: true, Basic: true, Premium: true, Enterprise: true}},
			{name: 'AI Insights', tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: true, Enterprise: true}},
			{
				name: 'Export Formats',
				tiers: {
					Starter: 'Basic (.txt, .csv)',
					Basic: 'Basic (.txt, .csv, .xlsx)',
					Premium: 'All formats',
					Enterprise: 'All formats'
				}
			},
			{
				name: 'Scheduled and Auto Listing Support',
				tiers: {Starter: false, Basic: true, Premium: true, Enterprise: true}
			},
			{
				name: 'Team Support',
				tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included'}
			},
			{
				name: 'Total Inventory Items',
				tiers: {Starter: 'Unlimited', Basic: 'Unlimited', Premium: 'Unlimited', Enterprise: 'Unlimited'}
			},
			{
				name: 'Auto Sale Detection',
				tiers: {
					Starter: '100 items/month',
					Basic: '1000 items/month',
					Premium: 'All listed items',
					Enterprise: 'All listed items'
				}
			},
			{
				name: 'Bulk Listing',
				tiers: {Starter: '10 items', Basic: '250 items', Premium: 'Unlimited items', Enterprise: 'Unlimited Items'}
			},
			{
				name: 'Total listings per Month',
				tiers: {Starter: '100 items', Basic: '1000 items', Premium: '5000 items', Enterprise: 'Unlimited Items'}
			},
			{name: 'Custom Titles/Templates per Marketplace', tiers: {Starter: false, Basic: true, Premium: true, Enterprise: true}},
			{name: 'Draft Listing', tiers: {Starter: true, Basic: true, Premium: true, Enterprise: true}},
			{
				name: 'Background Removal',
				tiers: {Starter: '100 items/month', Basic: '1000 items/month', Premium: 'Unlimited', Enterprise: 'Unlimited'}
			},
			{
				name: 'High Resolution image upload per listing',
				tiers: {Starter: '5 images/listing', Basic: '15 images/listing', Premium: '25 images/listing', Enterprise: '25 images/listing'}
			},
			{
				name: 'Import Inventory from marketplace listings',
				tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Included', Enterprise: 'Included'}
			},
			{name: 'API Access', tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included'}},
			{
				name: 'Accounting Software Integration',
				tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included'}
			},
		]
	},
	{
		name: 'Support',
		features: [
			{
				name: 'Customer Service',
				tiers: {Starter: 'Discord Chat', Basic: 'Limited help', Premium: 'Timely help', Enterprise: 'Dedicated help'}
			},
			{name: 'Priority Support', tiers: {Starter: 'Add-on', Basic: 'Add-on', Premium: 'Add-on', Enterprise: 'Included (24/7)'}},
			{
				name: 'Custom Features',
				tiers: {Starter: false, Basic: false, Premium: false, Enterprise: 'Contact us for custom requests'}
			},
		],
	},
	{
		name: 'Add-Ons',
		features: [
			{
				name: 'Additional Platforms',
				tiers: {
					Starter: '$4.99 per platform/month',
					Basic: '$4.99 per platform/month',
					Premium: 'Included',
					Enterprise: 'Included'
				}
			},
			{name: 'Priority Support', tiers: {Starter: '$9.99 / month', Basic: '$9.99 / month', Premium: '$9.99 / month', Enterprise: 'Included'}},
			{
				name: 'Team Support',
				tiers: {
					Starter: '$9.99/month per additional member',
					Basic: '$9.99/month per additional member',
					Premium: '$9.99/month per additional member',
					Enterprise: 'Included'
				}
			},
			{name: 'AI Insights', tiers: {Starter: '$4.99 / month', Basic: '$4.99 / month', Premium: 'Included', Enterprise: 'Included'}},
			{name: 'Marketplace Inventory Import', tiers: {Starter: '$3.99 / month', Basic: '$3.99 / month', Premium: 'Included', Enterprise: 'Included'}},
			{
				name: 'Email Tracking',
				tiers: {Starter: '$14.99 / month', Basic: '$14.99 / month', Premium: 'Full tracking', Enterprise: 'Full tracking'}
			},
			{name: 'API Access', tiers: {Starter: '$14.99 / month', Basic: '$14.99 / month', Premium: 'Included', Enterprise: 'Included'}},
			{
				name: 'Accounting Software Integration',
				tiers: {Starter: '$9.99 / month', Basic: '$9.99 / month', Premium: 'Included', Enterprise: 'Included'}
			}
		],
	},
];

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}

export default function Pricing() {
	const [enabled, setEnabled] = useState(false);

	const getPriceDisplay = (price:any) => {
		if (typeof price === 'string') {
			return price;
		}
		const formattedPrice = enabled ? (price * 12 * 0.8).toFixed(2) : price.toFixed(2);
		return `$${formattedPrice}`;
	};

	const getFullPriceDisplay = (price:any) => {
		if (typeof price === 'string') { return "" }
		return `$${(price * 12).toFixed(2)}`;
	};

	return (
		<div className="bg-transparent py-24 sm:py-32 dark:bg-gray-800">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
					<p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
						Plans for teams of all sizes
					</p>
				</div>
				<p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600 dark:text-gray-300">
					Choose the plan that best fits your needs. Whether you're just starting out or looking to scale, we have a
					plan for you.
				</p>

				<Field className="flex mt-12 items-center space-x-4 place-content-center">
					<Label as="span" className="ml-3 text-sm">
						<span className="font-medium text-gray-900 dark:text-gray-100">Monthly</span>
					</Label>
					<Switch
						checked={enabled}
						onChange={setEnabled}
						className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
					>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
					</Switch>
					<Label as="span" className="ml-3 text-sm">
						<span className="font-medium text-gray-900 dark:text-gray-100">Annual billing</span>{' '}
						<span className="text-gray-500">(Save 20%)</span>
					</Label>
				</Field>

				{/* xs to lg */}
				<div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
					{tiers.map((tier) => (
						<section
							key={tier.id}
							className={classNames(
								tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
								'p-8',
							)}
						>
							<h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
								{tier.name}
							</h3>
							<p className="mt-2 flex items-baseline relative gap-x-1 text-gray-900 dark:text-white">
								<span className="text-4xl font-bold">{getPriceDisplay(tier.priceMonthly)}</span>
								{
									enabled && (
										<span className="text-gray-500 line-through left-0">{getFullPriceDisplay(tier.priceMonthly)}</span>
									)
								}
								{
									tier.name.toLowerCase() !== "enterprise" && <span className="text-sm font-semibold">{enabled ? "/year" : "/month"}</span>
								}
							</p>
							<Link
								to={`${tier.href}${enabled ? "&interval=year" : "&interval=month"}`}
								aria-describedby={tier.id}
								className={classNames(
									tier.mostPopular
										? 'bg-indigo-600 text-white hover:bg-indigo-500'
										: 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
									'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
								)}
							>
								Buy plan
							</Link>
							<ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-900 dark:text-white">
								{sections.map((section) => (
									<li key={section.name}>
										<ul role="list" className="space-y-4">
											{section.features.map((feature: any) =>
												feature.tiers[tier.name] ? (
													<li key={feature.name} className="flex gap-x-3">
														<CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600"/>
														<span>
                              {feature.name}{' '}
															{typeof feature.tiers[tier.name] === 'string' ? (
																<span className="text-sm leading-6 text-gray-300">({feature.tiers[tier.name]})</span>
															) : null}
                            </span>
													</li>
												) : null,
											)}
										</ul>
									</li>
								))}
							</ul>
						</section>
					))}
				</div>

				{/* lg+ */}
				<div className="isolate mt-20 hidden lg:block">
					<div className="relative -mx-8">
						{tiers.some((tier) => tier.mostPopular) ? (
							<div className="absolute inset-x-4 inset-y-0 -z-10 flex">
								<div
									style={{marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 20}%`}}
									aria-hidden="true"
									className="flex w-1/5 px-4"
								>
									<div
										className="w-full rounded-t-xl border-x border-t border-gray-900/10 dark:border-gray-300 bg-gray-400/5"/>
								</div>
							</div>
						) : null}
						<table className="w-full table-fixed border-separate border-spacing-x-8 dark:border-gray-300 text-left">
							<caption className="sr-only">Pricing plan comparison</caption>
							<colgroup>
								<col className="w-1/5"/>
								<col className="w-1/5"/>
								<col className="w-1/5"/>
								<col className="w-1/5"/>
								<col className="w-1/5"/>
							</colgroup>
							<thead>
							<tr>
								<td/>
								{tiers.map((tier) => (
									<th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
										<div className="text-sm font-semibold text-center leading-7 text-gray-900 dark:text-white">{tier.name}</div>
									</th>
								))}
							</tr>
							</thead>
							<tbody>
							<tr>
								<th scope="row">
									<span className="sr-only">Price</span>
								</th>
								{tiers.map((tier, index) => (
									<td key={tier.id} className="px-6 text-center pt-2 xl:px-8">
										<div className="flex relative justify-center items-baseline text-center gap-x-1 text-gray-900 dark:text-white">
											{
												enabled && (
													<span className="absolute -top-3 text-gray-500 line-through left-3">{getFullPriceDisplay(tier.priceMonthly)}</span>
												)
											}
											<span className="text-2xl text-center font-bold">{getPriceDisplay(tier.priceMonthly)}</span>
											{
												tier.name.toLowerCase() !== "enterprise" && <span className="text-sm text-gray-400 font-semibold leading-6">{enabled ? "/year" : "/month"}</span>
											}
										</div>
										<Link
											to={`${tier.href}${enabled ? "&interval=year" : "&interval=month"}`}
											className={classNames(
												tier.mostPopular
													? 'bg-indigo-600 text-white hover:bg-indigo-500'
													: 'text-indigo-600 dark:text-gray-300 ring-1 ring-inset ring-indigo-200 dark:ring-0 dark:bg-gray-700 hover:ring-indigo-300 dark:hover:bg-gray-600 dark:hover:ring-0',
												'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
											)}
										>
											{tier.name.toLowerCase() === "enterprise" ? "Contact" : "Buy plan"}
										</Link>
									</td>
								))}
							</tr>
							{sections.map((section, sectionIdx) => (
								<Fragment key={section.name}>
									<tr>
										<th
											scope="colgroup"
											colSpan={4}
											className={classNames(
												sectionIdx === 0 ? 'pt-8' : 'pt-16',
												'pb-4 text-sm font-semibold leading-6 text-gray-900 dark:text-white',
											)}
										>
											{section.name}
											<div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10 dark:bg-gray-300/10"/>
										</th>
									</tr>
									{section.features.map((feature: any) => (
										<tr key={feature.name}>
											<th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900 dark:text-white">
												{feature.name}
												<div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5 dark:bg-gray-300/5"/>
											</th>
											{tiers.map((tier: any) => (
												<td key={tier.id} className="px-6 py-4 xl:px-8">
													{typeof feature.tiers[tier.name] === 'string' ? (
														<div className="text-center text-sm leading-6 text-gray-500 dark:text-gray-300">
															{feature.tiers[tier.name]}
														</div>
													) : (
														<>
															{feature.tiers[tier.name] === true ? (
																<CheckIcon aria-hidden="true" className="mx-auto h-5 w-5 text-indigo-600"/>
															) : (
																<MinusIcon aria-hidden="true"
																           className="mx-auto h-5 w-5 text-gray-400 dark:text-gray-300"/>
															)}

															<span className="sr-only">
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                </span>
														</>
													)}
												</td>
											))}
										</tr>
									))}
								</Fragment>
							))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
